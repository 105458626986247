/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'

import { useDispatch } from 'react-redux'
import { GatsbyImage } from 'gatsby-plugin-image'
import useProductOverHeadImage from '~/hooks/components/use-product-overhead-image'
import useProduct from '~/hooks/components/use-product'
import useBundle from '~/hooks/graphql/queries/use-bundle'

const SummaryImage = ({ slug, sku, forwardSx }) => {
  const { trackProductClicked } = useAnalytics()
  const product = useProduct({ slug })
  const bundle = useBundle(slug)

  const redirectSlug = bundle?.redirectSlug
  // const path = sku.includes('|') ? 'bundles' : 'products'
  // use this ⬆️ when we launch custome bundles
  const path = 'products'

  // if bundles is valid use the redirect slug in bundle to navigate to the correct PDP
  const url = `/${path}/${redirectSlug || slug}/`
  const dispatch = useDispatch()
  const { image } = useProductOverHeadImage({ sku })

  const bundleImage = bundle?.overheadProductImage

  if (!image && !bundleImage) {
    return null
  }

  return (
    <Link to={url} onClick={() => dispatch(trackProductClicked({ product }))}>
      <GatsbyImage
        style={{
          overflow: 'visible',
          position: 'absolute',
          left: 0,
          //transform: 'translate(-50%, -50%)',
          height: '120px',
          width: '120px',
          ...forwardSx,
        }}
        imgStyle={{
          objectFit: 'cover',
        }}
        image={
          bundleImage?.gatsbyImageData ||
          image?.gatsbyImageData ||
          product?.overheadProductImage?.gatsbyImageData
        }
        alt={
          bundleImage?.title ||
          image?.title ||
          product?.overheadProductImage?.gatsbyImageData
        }
      />
    </Link>
  )
}

export default SummaryImage
